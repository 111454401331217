import { useEffect, useState } from 'react';
import config from '../../config/index';
import AuthObj from '../../utility/authclass';

import ReportObj from '../../utility/reportclass';
import CustomerObj from '../../utility/customerclass';
import { Button, Pagination, Popconfirm, notification, Modal, Empty, AutoComplete, Input, DatePicker,Spin } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';
const photofield = ["tp_uq_pole_photo", "tp_uq_pole_paint_photo"];
const { RangePicker } = DatePicker;

const searchResult = (data) => {

    return data.map((item, itemkey) => {
        return {
            value: item.first_name+' '+item.last_name+' '+item.mobile, label: (
                <>{`${item.first_name} ${item.last_name} (${item.mobile})`}</>
            ),
            key: item.id,
            user:item
        }
    })
}

const List = () => {
    const [customerList, setReportList] = useState({ totalrecord: 0, list: [], uploadurl: "" });
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 10 });
    const [filter, setFilter] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageData, setImageData] = useState({ url: "", list: [] });
    const [useroptions, setuseroptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loader, setloader] = useState(false);
    const [downloadtype, setdownloadtype] = useState("geojson");
    const [currentUser, setCurrentUser] = useState({});
    const [section,setsection]=useState({list:[],options:[]})
    const [layers, setLayers] = useState([]);
    const [kv33,setkv33]=useState([])
    const [kv11,setkv11]=useState([])
    const [dtlist,setdtlist]=useState([])
    const [feederdata, setfeederdata] = useState({});
    let navigate = useNavigate();
    useEffect(() => {
        console.log('call customer useEffect');
        ReportObj.getList({ page: paging.currentpage, limit: paging.itemlimit, filter: filter }).then(list => {
            setReportList(() => {
                return { totalrecord: list?.collection?.count, list: list?.collection?.rows, uploadurl: list.uploadurl };
            })
        });
    }, [paging]);

    useEffect(() => {
        async function getLayers() {

            const list = await ReportObj.getCollectionLayer({});
            console.log(list);
            setLayers(list);
        }

        async function getCurrentUser() {

            const user = await AuthObj.getUserSession();
            console.log('User');
            console.log(user);
            setCurrentUser(user?.user);
         //   setLayers(list);
        }
        getCurrentUser();
        getLayers();
        getSection();
        getFeederList();
    }, []);
    
// For show Name in List
    async function getFeederList() {
        const flist = await CustomerObj.getkvfeeder11({});
          
          let filterdata=  flist.reduce((pre,current)=>{
              pre[current?.id]=current;
            return pre;

            },{});
          
            setfeederdata(filterdata);
            
       
      }
    async function getSection() {
        const sectionlist = await CustomerObj.getSection({});
  
  
        
        const worktypefiletrlist = sectionlist.map((item) => {
          return { value: item.id, label: `${item.section_name} (${item.section_code})` }
  
        })

        setsection({list:sectionlist,options:worktypefiletrlist});
        
      }

      async function getkvfeeder33(section_id) {
        const kvfeeder33list = await CustomerObj.getkvfeeder33({sectionid:section_id});             
        setkv33(kvfeeder33list);
      }

      async function getkvfeeder11(kvfeeder33_id) {
        const kvfeeder11list = await CustomerObj.getkvfeeder11({kvfeeder33_id:kvfeeder33_id});       
        setkv11(kvfeeder11list);
      }

      async function getTransformer(kvfeeder11_id) {
        const transformerlist = await CustomerObj.getTransformer({kvfeeder11_id:kvfeeder11_id});
        setdtlist(transformerlist);
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        setpage((pre) => {
            return { ...pre, currentpage: 1 }
        })
      /*  getUsers({ page: paging.currentpage, limit: paging.itemlimit, filter: filter }).then(list => {
            setReportList(() => {
                return { totalrecord: list.count, list: list.rows };
            })

        });*/
    }

    function getFeederName(id=0){
        
        if(common.isEmpty(feederdata[id])==false){

            return feederdata[id]?.feeder_name;
        }
        return "";
    }

    function getFeederCode(id=0){
        
        if(common.isEmpty(feederdata[id])==false){

            return feederdata[id]?.feeder_code;
        }
        return "";
    }

    const handleChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(name=='section_id'){
            setkv33([]);
            setkv11([]);
            setdtlist([]);
            setFilter((pre) => {                
                let newobj = {};
                newobj[name] = value;
                newobj['kvfeeder33_id'] = '';
                newobj['kvfeeder11_id'] = '';
                newobj['dt_id'] = '';
                
                console.log({ ...pre, ...newobj });

                return { ...pre, ...newobj }
    
            })
            
          await getkvfeeder33(value);
          return true;
        }

        if(name=='kvfeeder33_id'){         
            setkv11([]);
            setdtlist([]);

            setFilter((pre) => {                
                let newobj = {};
                newobj[name] = value;               
                newobj['kvfeeder11_id'] = '';
                newobj['dt_id'] = '';
                console.log({ ...pre, ...newobj });
                return { ...pre, ...newobj }
    
            })
          await getkvfeeder11(value);
          return true;
        }
        if(name=='kvfeeder11_id'){         
           
            
            setdtlist([]);
            setFilter((pre) => {
                console.log(pre);
                let newobj = {};
                newobj[name] = value;
                newobj['dt_id'] = '';
                return { ...pre, ...newobj }
    
            })
            await getTransformer(value);
            return true;
          //await getkvfeeder33();
        }

        

        

        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }

        })
    }
    const handlePageChange = (page) => {

        setpage((pre) => {
            return { ...pre, currentpage: page }
        })
    }
    const handleDelete = async (e, id, key) => {
        try{
            console.log(id);
            const customerinfo= await ReportObj.deleteRecord({id:id});
            customerList.list.splice(key, 1);
            let newarr = customerList.list;            
            setReportList((preList) => {
                return { totalrecord: customerList.totalrecord - 1, list: customerList.list };
            })
            
        }catch(err){
            notification.error({message:err.message});

        }

    }

    const haldelEdit = (e, id) => {
        navigate('/admin/report/list/' + id);

    }

    const ImageField = (items) => {
        const photoData = items.filter((item) => {

            return photofield.indexOf(item.field_name) !== -1;
        })

        console.log(photoData);
        return (<>
            {photoData.map((photoItem) => {
                return (<><p><a href={`${customerList.uploadurl}${photoItem.field_value}`} target="_blank">{photoItem.field_label}</a></p></>)

            })}
        </>);
    }

    const openImage = async (id) => {
        setImageData({ url: "", list: [] });
        const imageData = await ReportObj.getImageFields({ id: id });
        console.log(imageData);

        setImageData({ url: imageData.UPLOADPATH, list: imageData.fields });

        setIsModalOpen(true);
    }



    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handleSearch = (value,index) => {
        console.log(value);
        let filter = { searchkey: value };
        setloader(true);
        CustomerObj.getCustomers({ filter: filter }).then((data) => {
            setloader(false);
            console.log(searchResult(data.rows));
            setuseroptions(value ? searchResult(data.rows) : []);
        }).catch((err) => {

        })
    };

    const handleUserChange = (value) => {
        console.log('handleUserChange');
        console.log(value);
        if(value==''){
            filter['user_id']="";
        }
        setSearch(value);
    }

    const onSelect = (value,item) => {
           console.log(`Value ${value} index ${item}`);
           console.log(item);
        let name = 'user_id';
      
        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = item?.user?.id;
            return { ...pre, ...newobj }

        })
        console.log('onSelect', value);

    };


    const onChangeDate = (dates, dateStrings) => {
        console.log(dates);
        if (dates) {
            setFilter((pre) => {
              
                let newobj = {};
                newobj['startdate'] =  dateStrings[0];
                newobj['enddate'] =  dateStrings[1];
                return { ...pre, ...newobj }
    
            })
          
        } else {

            setFilter((pre) => {
              
                let newobj = {};
                newobj['startdate'] =  "";
                newobj['enddate'] =  "";
                return { ...pre, ...newobj }
    
            })
        }
      };

const downloadData =async (e)=>{

    //console.log(filter);
   // console.log(downloadtype);

    let fliteropt={...filter};
    fliteropt['fileType']=downloadtype;
   let qtr= new URLSearchParams(fliteropt).toString()
    console.log(qtr);
    console.log('Download');
    console.log(config.baseurl+'api/download.php?'+qtr);
    window.open(config.baseurl+'api/download.php?'+qtr, '_blank');
 
   // const list = await ReportObj.downloadReport({filter:filter,fileType:downloadtype});
   // console.log(list);
   // window.open(list.fileurl); 
 // window.location.replace(config.url+'downloadreport/?'+qtr);

}

    return (
        <>
            <div class="callout callout-info bg-white">

                <form class="row align-items-center" onSubmit={(e) => { handleSubmit(e) }}>
                <div class="col-md-2">
                        <label><strong>Code</strong></label>
                        <input type="text" className="form-control" name="code" onChange={(e)=>handleChange(e)}></input>
                    </div>
                    <div class="col-md-2">
                        <label><strong>User</strong></label>
                        <AutoComplete

                            style={{
                                width: "100%",

                            }}
                            options={useroptions}
                            onSelect={onSelect}
                            onSearch={handleSearch}
                            onChange={handleUserChange}
                            value={search}
                        >
                            <Input size="large" placeholder="User Search here" />

                        </AutoComplete>
                    </div>

                    <div class="col-md-2">
                        <label><strong>Layer</strong></label>
                        <select className="form-control" name="ext_id" onChange={(e)=>handleChange(e)}>
                            <option value="">Select </option>
                            {layers.map((item) => {

                                return (<option value={item.id}>{item.name}</option>)
                            })}
                        </select>
                    </div>
                    <div class="col-md-2">
                     <label><strong>Date Type</strong></label>
                     <select class="form-select" name="datetype" onChange={(e) => handleChange(e)}>
                                <option value="server">Server</option>
                                <option value="mobile">Mobile</option>                               
                            </select>
                </div>
                    <div class="col-md-3">
                        <label><strong>Date</strong></label>
                        <div class="form">
                        <RangePicker
      ranges={{
        Today: [moment(), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
      }}
      onChange={onChangeDate}
    />
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label><strong>Section</strong></label>
                        <div class="form">
                            <select class="form-select" name="section_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose Section</option>
                                {section.list.map((item)=>{

                                    return <option value={item.id}>{item.section_name}</option>
                                })}                              
                            </select>
                        </div>
    </div>
    <div class="col-md-2">
                        <label><strong>33 KV</strong></label>
                        <div class="form">
                            <select class="form-select" name="kvfeeder33_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose 33KV</option>
                                {kv33.map((item)=>{
                                    return ( <option value={item.id} key={item.id}>{`${item.feeder_name} (${item.feeder_code})`}</option>)
                                })}                                
                            </select>
                        </div>
    </div>

    <div class="col-md-2">
                        <label><strong>11 KV</strong></label>
                        <div class="form">
                            <select class="form-select" name="kvfeeder11_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose 11 KV</option>
                                {kv11.map((item)=>{
                                    return ( <option value={item.id} key={item.id}>{`${item.feeder_name} (${item.feeder_code})`}</option>)
                                })}  
                            </select>
                        </div>
    </div>

    <div class="col-md-2">
                        <label><strong>Dt</strong></label>
                        <div class="form">
                            <select class="form-select" name="dt_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose DT</option>
                                {dtlist.map((item)=>{
                                    return ( <option value={item.id} key={item.id}>{`${item.transformer_code}`}</option>)
                                })} 
                            </select>
                        </div>
    </div> 



                    <div class="col-md-1">
<br/>
                        <button className="btn btn-primary" >Search</button>                       
                    </div>
    {currentUser.role !=3 && <>
                    
                       <div class="col-md-1">
                        <br/>
                                <select class="form-select" name="type"  onChange={(e) => setdownloadtype(e.target.value)}>                               
                                    
                                     <option value="geojson">geojson</option>
                                     {filter?.ext_id > 0 && <> <option value="csv">CSV</option>                                        
                                    <option value="shapefile">Shapefile</option> </>  }                            
                                </select>
                                </div>
                        <div class="col-md-2">
                        <br/>{/**/}
                        <Link className="btn btn-primary" to="" onClick={()=>downloadData()}>Download</Link>
                        </div></>
                   
                }
                    
                </form>
            </div>
            <div className="card mb-4">
                <div className="card-header">Collection List</div>
                <div className="card-body">
                    <div className="tab-content rounded-bottom table-responsive">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th>User</th>
                                    <th>Feeder Name</th>
                                    <th>Feeder Code</th>
                                    <th>Photo</th>
                                    <th width="100px">Number</th>
                                    <th>Layer</th>
                                    <th>Status</th>
                                    <th>Server Date</th>
                                    <th>Survey Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customerList.list.map((item, itemKey) => {
                                        return (
                                            <tr key={item.id}>
                                                <td >{((paging.currentpage - 1) * paging.itemlimit) + (itemKey + 1)}</td>

                                                <td >{`${item?.customer?.first_name} ${item?.customer?.last_name}`}</td>
                                        <td >{getFeederName(item?.kvfeeder11_id)}</td>
                                        <td >{getFeederCode(item?.kvfeeder11_id)}</td>
                                                <td><button onClick={() => openImage(item.id)}>View Photo</button></td>
                                                <td ><div className="record-div">{(item.record_number) ? item.record_number : 'Temp : ' + item.mobile_record_number}</div></td>
                                                <td>{item?.objectextname?.name}</td>
                                                <td>{(item.status == 1) ? 'Active' : 'Inactive'}</td>
                                        <td >{common.datetimeFormat(item.date)}</td>
                                        <td >{common.datetimeFormat(item.app_save_date)}</td>
                                                <td >
                                                {currentUser.role !=3 && <>
                                                    <Link to={'/admin/report/list/' + item.id} target="_blank" className="btn btn-small btn-dark"><EditOutlined /> Edit</Link>
                                                    {/*<Button icon={<EditOutlined />} onClick={(e) => haldelEdit(e, item.id)}>Edit</Button>*/} &nbsp;
                                                
                                                {currentUser?.role==1 && <Popconfirm placement="bottom" title="Do You Want To Delete ?" onConfirm={(e) => handleDelete(e, item.id, itemKey)} okText="Yes" cancelText="No">
                                                        <Button icon={<DeleteOutlined />}>Delete</Button>
                                                    </Popconfirm>
                                    }
     </>}
                                                </td>
                                               
                                            </tr>

                                        )

                                    })
                                }

                            </tbody>
                        </table>
                        <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={customerList.totalrecord} onChange={handlePageChange} />

                        <Modal title="Images" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                            {imageData.list.map((item) => {
                                let imageUrl="";
                                if(common.isEmpty(item.s3)==false){
                                    imageUrl=item.s3;
                                }else{
                                    imageUrl=`${imageData.url}${item.field_value}`;
                                }

                                    if(currentUser.role !=3){
                                        return (<><a href={imageUrl} target="_blank"><img style={{ width: 200 }} src={imageUrl}></img></a><p>{item.field_label}</p></>)
                                    }else{
                                        return <><img style={{ width: 200 }} src={imageUrl}></img><p>{item.field_label}</p></>
                                    }
                                
                            })}

                            {imageData.list.length == 0 && <Empty></Empty>}
                        </Modal>

                    </div>
                </div>
            </div>
        </>
    )

}

export default List;