import { useEffect, useState } from 'react';
import config from '../../config/index';
import AuthObj from '../../utility/authclass';

import ReportObj from '../../utility/reportclass';
import CustomerObj from '../../utility/customerclass';
import CommonObj from '../../utility/commonclass';
import { Button, Pagination, Popconfirm, notification, Modal, Empty, AutoComplete, Input, DatePicker } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
//import common from '../../utility/commonclass';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';
const photofield = ["tp_uq_pole_photo", "tp_uq_pole_paint_photo"];
const { RangePicker } = DatePicker;


const searchResult = (data) => {

    return data.map((item, itemkey) => {
        return {
            value: item.first_name + ' ' + item.last_name + ' ' + item.mobile, label: (
                <>{`${item.first_name} ${item.last_name} (${item.mobile})`}</>
            ),
            key: item.id,
            user: item
        }
    })
}

const List = () => {
    const [customerList, setReportList] = useState([]);
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 10 });
    const [filter, setFilter] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageData, setImageData] = useState({ url: "", list: [] });
    const [useroptions, setuseroptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loader, setloader] = useState(false);
    const [downloadtype, setdownloadtype] = useState("geojson");
    const [currentUser, setCurrentUser] = useState({});
    const [section, setsection] = useState({ list: [], options: [] })
    const [layers, setLayers] = useState([]);
    const [kv33, setkv33] = useState([])
    const [kv11, setkv11] = useState([])
    const [dtlist, setdtlist] = useState([])
    const [dailydate, setdailydate] = useState("")
    let headers=[
       // {id:"div",value:"SUB DIVISION"},
        {id:"section",value:"SECTION"},
        {id:"feeder",value:"FEEDER NAME"},
        {id:"user",value:"USER"},
        {id:"ht-pole",value:" HT POLE"},                                           
        {id:"distance-ht-wire",value:"HT WIRE LENGTH"},
        {id:"distance-ht-cabel",value:"HT CABLE LENGTH"},
        {id:"lt-pole",value:"LT POLE"},
        {id:"distance-lt-wire",value:"LT WIRE LENGTH"},                                        
        {id:"distance-lt-cable",value:"LT CABLE LENGTH"},                                        
        {id:"total-ht",value:"TOTAL HT LENGTH"},
        {id:"total-lt",value:"TOTAL LT LENGTH"},
        {id:"2",value:"TOTAL POLE"},
        {id:"14",value:"DTCOUNT"},                                   
        {id:"8",value:"CONSUMER COUNT"},
        
     ]
    let navigate = useNavigate();
    useEffect(() => {
        console.log('call customer useEffect');
        ReportObj.getuserwiseList({ page: paging.currentpage, limit: paging.itemlimit, filter: filter }).then(list => {
            setReportList(() => {
                return list?.collection;
            })
        });
    }, [paging]);

    useEffect(() => {
        async function getLayers() {

            const list = await ReportObj.getCollectionLayer({});
            console.log(list);
            setLayers(list);
        }

        async function getCurrentUser() {

            const user = await AuthObj.getUserSession();
            console.log('User');
            console.log(user);
            setCurrentUser(user?.user);
            //   setLayers(list);
        }
        getCurrentUser();
        getLayers();
        getSection();
    }, []);

    async function getSection() {
        const sectionlist = await CustomerObj.getSection({});



        const worktypefiletrlist = sectionlist.map((item) => {
            return { value: item.id, label: `${item.section_name} (${item.section_code})` }

        })

        setsection({ list: sectionlist, options: worktypefiletrlist });

    }

    async function getkvfeeder33(section_id) {
        const kvfeeder33list = await CustomerObj.getkvfeeder33({ sectionid: section_id });
        setkv33(kvfeeder33list);
    }

    async function getkvfeeder11(kvfeeder33_id) {
        const kvfeeder11list = await CustomerObj.getkvfeeder11({ kvfeeder33_id: kvfeeder33_id });
        setkv11(kvfeeder11list);
    }

    async function getTransformer(kvfeeder11_id) {
        const transformerlist = await CustomerObj.getTransformer({ kvfeeder11_id: kvfeeder11_id });
        setdtlist(transformerlist);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setpage((pre) => {
            return { ...pre, currentpage: 1 }
        })

    }

    const handleChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name == 'section_id') {
            setkv33([]);
            setkv11([]);
            setdtlist([]);
            setFilter((pre) => {
                let newobj = {};
                newobj[name] = value;
                newobj['kvfeeder33_id'] = '';
                newobj['kvfeeder11_id'] = '';
                newobj['dt_id'] = '';

                console.log({ ...pre, ...newobj });

                return { ...pre, ...newobj }

            })

            await getkvfeeder33(value);
            return true;
        }

        if (name == 'kvfeeder33_id') {
            setkv11([]);
            setdtlist([]);

            setFilter((pre) => {
                let newobj = {};
                newobj[name] = value;
                newobj['kvfeeder11_id'] = '';
                newobj['dt_id'] = '';
                console.log({ ...pre, ...newobj });
                return { ...pre, ...newobj }

            })
            await getkvfeeder11(value);
            return true;
        }
        if (name == 'kvfeeder11_id') {


            setdtlist([]);
            setFilter((pre) => {
                console.log(pre);
                let newobj = {};
                newobj[name] = value;
                newobj['dt_id'] = '';
                return { ...pre, ...newobj }

            })
            await getTransformer(value);
            return true;
            //await getkvfeeder33();
        }





        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }

        })
    }
 






    const onChangeDate = (dates, dateStrings) => {
        console.log(dates);
        if (dates) {
            setFilter((pre) => {

                let newobj = {};
                newobj['startdate'] = dateStrings[0];
                newobj['enddate'] = dateStrings[1];
                return { ...pre, ...newobj }

            })

        } else {

            setFilter((pre) => {

                let newobj = {};
                newobj['startdate'] = "";
                newobj['enddate'] = "";
                return { ...pre, ...newobj }

            })
        }
    };

 

    function convertToCSV(json) {
        var fields = Object.keys(json[0]);
        let headerfields=headers.map((item)=>{

            return item?.value;
        });

            console.log(fields);
            console.log(headerfields);
        var replacer = function(key, value) { return value === null ? '' : value } 
        var csv = json.map(function(row){
          return fields.map(function(fieldName){
            return JSON.stringify(row[fieldName], replacer)
          }).join(',')
        })
        csv.unshift(headerfields.join(',')) // add header column
        csv = csv.join('\r\n');
        return csv;


      }

      function downloadCSV(json) {
        const csv = convertToCSV(json);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        let datename=(filter?.startdate==undefined)?CommonObj.dateFormaturl():CommonObj.dateFormaturl(filter?.startdate);
        a.download = datename+'.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

      const downloaddata=async()=>{
console.log(customerList);
let rowdata=[...customerList];
console.log(rowdata);
let ffdata=rowdata.map((item)=>{
    let tmp={};
        for(let ii of headers){
            tmp[ii?.value]=CommonObj.isEmpty(item[ii?.id])==false?item[ii?.id]:"0";
        }

    return tmp;
})
console.log(ffdata);


        downloadCSV(ffdata);
       

      }


    return (
        <>
            <div class="callout callout-info bg-white">

                <form class="row align-items-center" onSubmit={(e) => { handleSubmit(e) }}>
                <div class="col-md-2">
                     <label><strong>Date Type</strong></label>
                     <select class="form-select" name="datetype" onChange={(e) => handleChange(e)}>
                                <option value="server">Server</option>
                                <option value="mobile">Mobile</option>                               
                            </select>
                </div>
                    <div class="col-md-3">
                        <label><strong>Date</strong></label>
                        <div class="form">
                            <RangePicker
                                ranges={{
                                    Today: [moment(), moment()],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                }}
                                onChange={onChangeDate}
                            />
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label><strong>Section</strong></label>
                        <div class="form">
                            <select class="form-select" name="section_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose Section</option>
                                {section.list.map((item) => {

                                    return <option value={item.id}>{item.section_name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label><strong>33 KV</strong></label>
                        <div class="form">
                            <select class="form-select" name="kvfeeder33_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose 33KV</option>
                                {kv33.map((item) => {
                                    return (<option value={item.id} key={item.id}>{`${item.feeder_name} (${item.feeder_code})`}</option>)
                                })}
                            </select>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label><strong>11 KV</strong></label>
                        <div class="form">
                            <select class="form-select" name="kvfeeder11_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose 11 KV</option>
                                {kv11.map((item) => {
                                    return (<option value={item.id} key={item.id}>{`${item.feeder_name} (${item.feeder_code})`}</option>)
                                })}
                            </select>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label><strong>Dt</strong></label>
                        <div class="form">
                            <select class="form-select" name="dt_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose DT</option>
                                {dtlist.map((item) => {
                                    return (<option value={item.id} key={item.id}>{`${item.transformer_code}`}</option>)
                                })}
                            </select>
                        </div>
                    </div>



                    <div class="col-md-1">
                        <br />
                        <button className="btn btn-primary" >Search</button>
                       
                    </div>

                    <div class="col-md-1">
                       
                       
                    </div>

                    {/*<div class="col-md-2">
                        <br/>
                     <DatePicker onChange={(date, dateString)=>{setdailydate(dateString)}} />
                   
                            </div>*/}

                    <div class="col-md-3"><br />
                  {/*config.baseurl+"api/userwisedownload.php?date="+dailydate*/} 
                    {/*a className="btn btn-primary" target="_blank" rel="noopener noreferrer" href={} >
                        
                            Download Daily Report</a>*/}
                        <Link to={""} className="btn btn-primary" onClick={(e)=>downloaddata()}> Download Daily Report</Link>
                   
                   </div>


                </form>
            </div>
            <div className="card mb-4">
                <div className="card-header">User Wise List</div>
                <div className="card-body">
                    <div className="tab-content rounded-bottom table-responsive ">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    {headers.map((item,itemkey)=>{
                                        return( <th key={itemkey}>{item?.value}</th>);
                                    })}
                                  
                                    

                                </tr>
                            </thead>
                            <tbody>
                              
                                { customerList.map((item, i) => {
                                        let pole=0;
                                        let Cable=0;
                                        let Wire=0;
                                        let Transformer=0;
                                        let ConsumerMeter=0;
                                        let Cabinet=0;
                                        let CommsDevice=0;
                                        let Switch=0;
                                        let ConnectorPoint=0;
                                        let ConnectorSegment=0;
                                        let MeasuringEquipment=0;
                                        let ProtectiveEquipment=0;
                                        let IsolatingEquipment=0;
                                        let SubStation=0;
                                        let Meter=0;

                                        let cabeldistance=0;
                                        let wiredistance=0;
                                       /* customerList[item]?.extData*/

                                       

                                       if(CommonObj.isEmpty(customerList[item]?.extData['distance-3'])==false){
                                        cabeldistance=customerList[item]?.extData['distance-3'];
                                       }
                                       if(CommonObj.isEmpty(customerList[item]?.extData['distance-17'])==false){
                                        wiredistance=customerList[item]?.extData['distance-17'];
                                       }

                                       if(CommonObj.isEmpty(customerList[item]?.extData['1'])==false){
                                        Cabinet=customerList[item]?.extData['1'];
                                       }

                                       if(CommonObj.isEmpty(customerList[item]?.extData['2'])==false){
                                        pole=customerList[item]?.extData['2'];
                                       }

                                       if(CommonObj.isEmpty(customerList[item]?.extData['3'])==false){
                                        Cable=customerList[item]?.extData['3'];
                                       }

                                       if(CommonObj.isEmpty(customerList[item]?.extData['4'])==false){
                                        CommsDevice=customerList[item]?.extData['4'];
                                       }


                                       if(CommonObj.isEmpty(customerList[item]?.extData['5'])==false){
                                        Switch=customerList[item]?.extData['5'];
                                       }
                                       if(CommonObj.isEmpty(customerList[item]?.extData['6'])==false){
                                        ConnectorPoint=customerList[item]?.extData['6'];
                                       }
                                       if(CommonObj.isEmpty(customerList[item]?.extData['7'])==false){
                                        ConnectorSegment=customerList[item]?.extData['7'];
                                       }
                                       if(CommonObj.isEmpty(customerList[item]?.extData['8'])==false){
                                        ConsumerMeter=customerList[item]?.extData['8'];
                                       }
                                      
                                       if(CommonObj.isEmpty(customerList[item]?.extData['11'])==false){
                                        IsolatingEquipment=customerList[item]?.extData['11'];
                                       }
                                       if(CommonObj.isEmpty(customerList[item]?.extData['12'])==false){
                                        MeasuringEquipment=customerList[item]?.extData['12'];
                                       }
                                       if(CommonObj.isEmpty(customerList[item]?.extData['13'])==false){
                                        Meter=customerList[item]?.extData['13'];
                                       }
                                       if(CommonObj.isEmpty(customerList[item]?.extData['14'])==false){
                                        Transformer=customerList[item]?.extData['14'];
                                       }
                                       if(CommonObj.isEmpty(customerList[item]?.extData['15'])==false){
                                        ProtectiveEquipment=customerList[item]?.extData['15'];
                                       }
                                       
                                       if(CommonObj.isEmpty(customerList[item]?.extData['17'])==false){
                                        Wire=customerList[item]?.extData['17'];
                                       }
                                       if(CommonObj.isEmpty(customerList[item]?.extData['18'])==false){
                                        SubStation=customerList[item]?.extData['18'];
                                       }

                                        

                                        

                                   return(item !="0" &&  <tr>
                                                {
                                                headers.map((headeri)=>{
                                                return (<td>{(item[headeri?.id])?item[headeri?.id]:0}</td>);

                                                })
                                                }

                                    </tr>)
                                })
                            }

                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </>
    )

}

export default List;