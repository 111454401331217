let development={
//url:'http://localhost:3002/',
url:'http://survey7.geomaticxmap.in:3002/',
//sessionkey:'_surveyDashboard'
imageurl:"https://3g6q245kwc.execute-api.ap-south-1.amazonaws.com/survey/saveimage"
}

let production={
    url:'',
    sessionkey:'_survey7Dashboard'
    
}
let config=(process.env.REACT_APP_ENVIRONMENT==='production')?production:development;

export default config;
