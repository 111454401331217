import { useEffect, useState, useCallback } from 'react';
import config from '../../config/index';
import AuthObj from '../../utility/authclass';

import ReportObj from '../../utility/reportclass';
import CustomerObj from '../../utility/customerclass';
import { Button, Pagination, Popconfirm, notification, Modal, Empty, AutoComplete, Input, DatePicker, Drawer, Select } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined, InfoCircleFilled, FilterOutlined, CloseCircleOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';
const { RangePicker } = DatePicker;

const searchResult = (data) => {

    return data.map((item, itemkey) => {
        return {
            value: item.first_name+' '+item.last_name+' '+item.mobile, label: (
                <>{`${item.first_name} ${item.last_name} (${item.mobile})`}</>
            ),
            key: item.id,
            user:item
        }
    })
}
const Filter = (props) => {
    const visible = props?.visible;
    const currentUser=props?.currentUser;
    const totalrecord=props?.totalrecord;
    const [filterlist, setfilterlist]=useState({
        userlist:[],
        layers:[],
        sectionlist:[],
        kv33list:[],
        kv11list:[],
        dtlist:[]
    }); // all list
    const [arg,setFilterArg]=useState({
        code:"",
        user_id:"",
        ext_id:"",
        startdate:"",
        enddate:"",
        section_id:"",
        kvfeeder33_id:"",
        kvfeeder11_id:"",
        dt_id:"",
        paint_code:""



    });
    const [useroptions, setuseroptions] = useState([]);


    useEffect(() => {
        async function getLayers() {

            const list = await ReportObj.getCollectionLayer({});
            console.log(list);
            setfilterlist((pre)=>({...pre,layers:list}))
        }

        async function getSection() {
            const sectionlist = await CustomerObj.getSection({});
    
            setfilterlist((pre)=>({...pre,sectionlist:sectionlist}))
    
        }

        getLayers();
        getSection();

    },[])

    const handleSubmit = async (e) => {
        e.preventDefault();
        props?.getResults(arg)

    }

    async function getkvfeeder33(section_id) {
        const kvfeeder33list = await CustomerObj.getkvfeeder33({ sectionid: section_id });
       setfilterlist((pre)=>({...pre, kv33list:kvfeeder33list}))
    }

    async function getkvfeeder11(kvfeeder33_id) {
        const kvfeeder11list = await CustomerObj.getkvfeeder11({ kvfeeder33_id: kvfeeder33_id });
        setfilterlist((pre)=>({...pre, kv11list:kvfeeder11list}))
    }

    async function getTransformer(kvfeeder11_id) {
        const transformerlist = await CustomerObj.getTransformer({ kvfeeder11_id: kvfeeder11_id });
     
        setfilterlist((pre)=>({...pre, dtlist:transformerlist}))
    }

    const handleChange = async (e) => {
       let name = e.target.name;
        let value = e.target.value;
        console.log(name);
        console.log(value);
         if (name == 'section_id') {
            setfilterlist((pre)=>({...pre,
                kv33list:[],
                kv11list:[],
                dtlist:[]}));

                setFilterArg((pre)=>({...pre,kvfeeder33_id:"",
                kvfeeder11_id:"",
                dt_id:"",
                section_id:value}))

            await getkvfeeder33(value);
            return true;
        }

        if (name == 'kvfeeder33_id') {
            setfilterlist((pre)=>({...pre,               
                kv11list:[],
                dtlist:[]}))

                setFilterArg((pre)=>({...pre,
                kvfeeder11_id:"",
                dt_id:"",
                kvfeeder33_id:value}))


            await getkvfeeder11(value);
            return true;
        }
      if (name == 'kvfeeder11_id') {


        setfilterlist((pre)=>({...pre, 
            dtlist:[]}))

            setFilterArg((pre)=>({...pre,
                dt_id:"",
                kvfeeder11_id:value}))        
            await getTransformer(value);
            return true;
           
        }

        setFilterArg((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }

        })
    }


    const onSelect = (value, item) => {
        console.log(`Value ${value} index ${item}`);
        console.log(item);
        console.log(item?.user?.id);
        let name = 'user_id';
        setFilterArg((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = item?.user?.id;
            return { ...pre, ...newobj }

        })

       
        console.log('onSelect', value);

    };

    const handleSearch = (value, index) => {
        console.log(value);
        let filter = { searchkey: value };
       // setloader(true);
        CustomerObj.getCustomers({ filter: filter }).then((data) => {
            console.log(data)
         //   setloader(false);
          //  console.log(searchResult(data.rows));
          let list=value ? searchResult(data.rows) : [];
          setfilterlist((pre)=>({...pre,useroptions:list}))
            
        }).catch((err) => {

        })
    };
    const onChangeDate = (dates, dateStrings) => {
        console.log(dates);
        if (dates) {
            setFilterArg((pre) => {

                let newobj = {};
                newobj['startdate'] = dateStrings[0];
                newobj['enddate'] = dateStrings[1];
                return { ...pre, ...newobj }

            })

        } else {

            setFilterArg((pre) => {

                let newobj = {};
                newobj['startdate'] = "";
                newobj['enddate'] = "";
                return { ...pre, ...newobj }

            })
        }
    };

    const handleUserChange = (value) => {
        console.log('handleUserChange');
        console.log(value);
        if (value == '') {
         //   filter['user_id'] = "";
        }
       // setSearch(value);
    }
    return (<>
        <Drawer
            title="Layer List"
            placement="right"
            closable={false}
            onClose={(e) => props?.showFilter(false)}
            getContainer={false}
            visible={visible}
            key="drawerlayer"
            forceRender={true}
        >
            <form className="align-items-center" onSubmit={(e) => { handleSubmit(e) }}>
                <div className="row">
                <div className="col-md-12">
                        <label><strong>Migration ID</strong></label>
                        <input type="text" className="form-control" name="code" onChange={(e) => handleChange(e)}></input>
                    </div>
                    <div className="col-md-12">
                        <label><strong>Paint Code</strong></label>
                        <input type="text" className="form-control" name="paint_code" onChange={(e) => handleChange(e)}></input>
                    </div>
                    <div className="col-md-12">
                        <label><strong>User</strong></label>
                        <AutoComplete

                            style={{
                                width: "100%",

                            }}
                            options={filterlist?.useroptions}
                            onSelect={onSelect}
                            onSearch={handleSearch}
                            onChange={handleUserChange}
                           
                        >
                            <Input size="large" placeholder="User Search here" />

                        </AutoComplete>
                    </div>

                    <div className="col-md-12">
                        <label><strong>Layer</strong></label>
                        <select className="form-control" name="ext_id" onChange={(e) => handleChange(e)}>
                            <option value="">Select </option>
                            {filterlist?.layers.map((item) => {

                                return (<option value={item.id}>{item.name}</option>)
                            })}
                        </select>
                    </div>

                   {/* <div className="col-md-12">
                        <label><strong>Composite Type</strong></label>
                        <input type="text" className="form-control" name="composite_type" onChange={(e) => handleChange(e)}></input>
                    </div>

                    <div className="col-md-12">
                        <label><strong>Element Type</strong></label>
                        <input type="text" className="form-control" name="element_type" onChange={(e) => handleChange(e)}></input>
                    </div>

                    <div className="col-md-12">
                        <label><strong>Nominal Voltage</strong></label>
                        <input type="text" className="form-control" name="nominal_voltage" onChange={(e) => handleChange(e)}></input>
                    </div>

                    <div className="col-md-12">
                        <label><strong>No of HV</strong></label>
                        <input type="text" className="form-control" name="no_of_hv" onChange={(e) => handleChange(e)}></input>
                    </div>
                    <div className="col-md-12">
                        <label><strong>No of MV</strong></label>
                        <input type="text" className="form-control" name="no_of_mv" onChange={(e) => handleChange(e)}></input>
                    </div>

                    <div className="col-md-12">
                        <label><strong>No of LV</strong></label>
                        <input type="text" className="form-control" name="no_of_lv" onChange={(e) => handleChange(e)}></input>
                    </div>

                    <div className="col-md-12">
                        <label><strong>Branch</strong></label>
                        <input type="text" className="form-control" name="no_branch" onChange={(e) => handleChange(e)}></input>
                    </div>
                    <div className="col-md-12">
                        <label><strong>Jumper</strong></label>
                        <input type="text" className="form-control" name="jumper" onChange={(e) => handleChange(e)}></input>
                    </div>
                    <div className="col-md-12">
                        <label><strong>Cut Point</strong></label>
                        <input type="text" className="form-control" name="cut_point" onChange={(e) => handleChange(e)}></input>
                    </div>*/}

                    
                    


                    

                    
                    <div className="col-md-12">
                        <label><strong>Date</strong></label>
                        <div className="form">
                            <RangePicker
                                ranges={{
                                    Today: [moment(), moment()],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                }}
                                onChange={onChangeDate}
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <label><strong>Section</strong></label>
                        <div className="form">
                            <select className="form-select" name="section_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose Section</option>
                                {filterlist?.sectionlist.map((item) => {

                                    return <option value={item.id}>{item.section_name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label><strong>33 KV</strong></label>
                        <div className="form">
                            <select className="form-select" name="kvfeeder33_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose 33KV</option>
                                {filterlist?.kv33list.map((item) => {
                                    return (<option value={item.id} key={item.id}>{`${item.feeder_name} (${item.feeder_code})`}</option>)
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <label><strong>11 KV</strong></label>
                        <div className="form">
                            <select className="form-select" name="kvfeeder11_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose 11 KV</option>
                                {filterlist?.kv11list.map((item) => {
                                    return (<option value={item.id} key={item.id}>{`${item.feeder_name} (${item.feeder_code})`}</option>)
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <label><strong>Dt</strong></label>
                        <div className="form">
                            <select className="form-select" name="dt_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose DT</option>
                                {filterlist?.dtlist.map((item) => {
                                    return (<option value={item.id} key={item.id}>{`${item.transformer_code}`}</option>)
                                })}
                            </select>
                        </div>
                    </div>



                    <div className="col-md-12">
                        <br />
                        <button className="btn btn-primary btn-sm" >Search</button>
                    </div>
                    {currentUser?.role != 3 && <>

                        <div className="col-md-12">
                            <br />
                            <select className="form-select" name="type" onChange={(e) => {}}>

                                <option value="geojson">geojson</option>
                                {/*filter?.ext_id > 0 && <> <option value="csv">CSV</option>
                                    <option value="shapefile">Shapefile</option> </>*/}
                            </select>
                            <br />
                            <Link className="btn btn-primary btn-sm" to="" onClick={(e) => {props?.downloadData(e)}}>Download</Link>
                        </div>
                      
                        <div className="col-md-12">
                            Total Record : {totalrecord}
                            <br />
                            
                        </div>
                    </>

                    }

                </div>

            </form>


        </Drawer>
    </>)
}

export default Filter;