import { Button, Form, Input, Switch, notification, Select,Upload,message } from 'antd';
import { useState, useEffect } from 'react';
import CustomerObj from '../../utility/customerclass';
import { CheckOutlined, CloseOutlined,AimOutlined,UploadOutlined } from '@ant-design/icons';

import { useParams,useNavigate, Link } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import marker from '../../icons/marker.jpg'
import Config from '../../config/index';
const AnyReactComponent = ({ text }) => <div><img src={marker} style={{width:20}}></img></div>;


const UploadData = () => {
  const navigate=useNavigate();
  const componentSize = 'large';
  const [userData, setuserData] = useState([]);
  const [formData, setformData] = useState({});
  const [workTypeData, setworkTypeData] = useState([{value:"mv",label:"Pole"},{value:"cabel",label:"Cabel"},{value:"wire",label:"Wire"},{value:"dt",label:"DT"}]);
  const [kvfeeder33Data, setkvfeeder33Data] = useState([]);
  const [kvfeeder33Option, setkvfeeder33Option] = useState([]);
  const [worktype, setworktype] = useState(0);
  const [kvfeeder11Data, setkvfeeder11Data] = useState([]);
  const [kvfeeder11Option, setkvfeeder11Option] = useState([]);
  const [workordercode, setworkordercode] = useState("");
  const [workordercodeedit, setworkordercodeedit] = useState(0);
  const [location,setlocation]=useState({})
  const [section,setsection]=useState({list:[],options:[]})
  const [transformerList,settransformerList]=useState({list:[],options:[]})
  const { id } = useParams();
  const [geofile, setgeofile] = useState("");
  const [dtrequired,setdtrequired]=useState([])
  const [form] = Form.useForm();
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };
  useEffect(() => {
    async function getUserData() {
      const userlist = await CustomerObj.getCustomers({isall:1});
      console.log(userlist);
      const userfiletrlist = userlist.rows.map((item) => {
        return { value: item.id, label: `${item.first_name} ${item.last_name} (${item.email})` }


      })
      console.log(userfiletrlist);
      setuserData(userfiletrlist);
    }

    async function getSection() {
      const sectionlist = await CustomerObj.getSection({});


      
      const worktypefiletrlist = sectionlist.map((item) => {
        return { value: item.id, label: `${item.section_name} (${item.section_code})` }

      })
      setsection({list:sectionlist,options:worktypefiletrlist});
      
    }


    async function getWorkType() {
      const worktypelist = await CustomerObj.getWorkType({});
      console.log(worktypelist);
      const worktypefiletrlist = worktypelist.map((item) => {
        return { value: item.id, label: `${item.work_name}` }

      })
      console.log(worktypefiletrlist);
      setworkTypeData([{value:2,label:"Pole"}]);
    }

    getSection();
   
    //getUserData();
    //getWorkType();


  }, []);

  async function getkvfeeder33(e) {
   // console.log(e);
    const kvfeeder33list = await CustomerObj.getkvfeeder33({section_id:e});
    console.log(kvfeeder33list);
    setkvfeeder33Data(kvfeeder33list);
    const kvfeeder33listfiletr = kvfeeder33list.map((item,key) => {
      return { value: item?.id, label: `${item.feeder_name}`,item:item }

    })
    console.log(kvfeeder33listfiletr);
    setkvfeeder33Option(kvfeeder33listfiletr);
  }

  async function getkvfeeder11(kv33_id) {
    const kvfeeder11list = await CustomerObj.getkvfeeder11({kvfeeder33_id:kv33_id});
    console.log(kvfeeder11list);
    setkvfeeder11Data(kvfeeder11list);
    const kvfeeder11listfiletr = kvfeeder11list.map((item,key) => {
      return { value: item.id, label: `${item.feeder_name}`,item:item }

    })
    console.log(kvfeeder11listfiletr);
    setkvfeeder11Option(kvfeeder11listfiletr);
  }


  async function getTransformer(kvfeeder11_id) {
    const transformerlist = await CustomerObj.getTransformer({kvfeeder11_id:kvfeeder11_id});
    console.log(transformerlist);
 
    const transformerlistOption = transformerlist.map((item,key) => {
      return { value: item.id, label: `${item.transformer_code}`, object:item }

    })   
    settransformerList({list:transformerlist,options:transformerlistOption});
  }



  const onFinish = async (values) => {
    try {
      console.log(values);
//      return false;
      values['file'] =geofile;
  
     if(!values['file']){
      notification.error({ message: "Please Fill All Required Fields" });
       return false;
     }  

     
     
     const customerinfo = await CustomerObj.uploaddata(values);
     console.log(customerinfo);
     if(customerinfo!==false){
      notification.success({ message: "File has been uploaded successfully" });
      form.resetFields();
      navigate("/admin/uploadgeojson");
     }
    
      /*if (Object.keys(customerinfo).length > 0) {
        form.resetFields();
      }*/
    } catch (err) {
      notification.error({ message: err.message })

    }






  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = (value, e) => {
    console.log(`selected ${value}`);
    console.log(e);
    console.log(`selected ${e}`);
  };

  const onChange33kva = async (value,option)=>{

    getkvfeeder11(option?.item?.id)
    /*if(form.getFieldsValue()['layer_id']=="mv" || form.getFieldsValue()['layer_id']=="lv"){
      setworkordercode(option?.item?.feeder_code);
      formData['workordecode'] = option?.item?.feeder_code;
      setformData(formData);
    }else{
      getkvfeeder11(option?.item?.id)

    }*/
   
  }

  const onChange11kva = (value,option)=>{
    
      console.log(form.getFieldsValue());

    if(form.getFieldsValue()['layer_id']=="mv"){
     
      setworkordercode(option?.item?.feeder_code);
      formData['workordecode'] = option?.item?.feeder_code;    
      setformData(formData);

    }else if(form.getFieldsValue()['layer_id']==5){
       getTransformer(value);

    }else if(form.getFieldsValue()['layer_id']==4){
      getTransformer(value);

     }
  }

  const onChangeWorktype = (value, option) => {

    console.log(`selected ${value}`);
    console.log(option);

    formData['layer_id'] = value;
    setformData(formData);
    setworktype(value);
    console.log(formData);
    if(value==3 || value==2 || value==4 || value==5){
      setworkordercodeedit(0);
    }else{
      setworkordercodeedit(1);
    }

  }
  const onSearch = (value) => {
    console.log('search:', value);
  };

  const handelChange = (e) => {
    console.log(e.target.value);
    formData[e.target.name] = e.target.value;
    setformData((pre) => ({ ...pre, ...formData }));
    // console.log(e.target.name);



  }
  const workhorderhandelChange = (e) => {
    //console.log(e.target.value);

    setworkordercode(e.target.value);
  //  formData[e.target.name] = e.target.value;
  //  setformData((pre) => ({ ...pre, ...formData }));
    // console.log(e.target.name);



  }

  const increaseCharacter =(ch)=>{

   return String.fromCharCode(ch.charCodeAt() + 1)
  } 

  

  const _onClick = ({x, y, lat, lng, event}) =>{
    console.log(x, y, lat, lng, event)
    setlocation({lat:lat,lng:lng});
    console.log(JSON.stringify({lat:lat,lng:lng}))
   


  
  }
  
  const addmoreDt = async (e)=>{
   let codearr = workordercode.split(",");
   if(codearr.length > 25){
     return false;
   }
   let lastCode= codearr[codearr.length-1];
   let lastCharacter=lastCode[lastCode.length-1];
   console.log(lastCharacter);
let nextCharacter=increaseCharacter(lastCharacter);
console.log(nextCharacter);
let newcode = lastCode.slice(0, -1) +nextCharacter;

    console.log(newcode);
    codearr.push(newcode);
    setworkordercode(codearr.join(","));
    
  }


  const onuploadChange=async(info)=>{

    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      console.log("done");
      console.log(info?.file?.response?.data?.filename);
      setgeofile(info?.file?.response?.data?.filename);
    //  message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }

  }


  const beforeUpload = (file) => {
    //console.log("filetype");
   // console.log(file);
    
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('Image must smaller than 10MB!');
    }
    return  isLt2M;
  };

  return (
    <>
      <div className="card mb-4">
        <div className="card-header">Task Add</div>
        <div className="card-body">
         
          <Form
            form={form}
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 14,
            }}
            layout="horizontal"
            size={componentSize}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          // initialValues={userData}
          >

           {/* <Form.Item label="User" name="user_id" rules={[
              {
                required: true,
                message: 'Please input User!',
              },
            ]}>
              <Select
                showSearch
                placeholder="Select a User"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={userData}
              />
            </Form.Item>*/}
            <Form.Item label="Layer" name="layer_id" rules={[
              {
                required: true,
                message: 'Please input Layer!',
              },
            ]}>
              <Select
                showSearch
                placeholder="Select a layer Type"
                optionFilterProp="children"
                onChange={onChangeWorktype}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={workTypeData}
              />
            </Form.Item>
            <Form.Item label="Section" name="section_id" rules={[
              {
                required: true,
                message: 'Please input Section!',
              },
            ]}>
              <Select
               
                showSearch
                placeholder="Select Section"
                optionFilterProp="children"
                onChange={(e,v)=>{
                  console.log(e)
                  console.log(v)
                 getkvfeeder33(e);


                }}
                
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={section.options}
              />
            </Form.Item>

            
             <Form.Item label="33 KV" name="33_id" rules={[
              {
                required: true,
                message: 'Please input 33 KV!',
              },
            ]}>
              <Select                
                showSearch
                placeholder="Select a 33 KV"
                optionFilterProp="children"
                onChange={onChange33kva}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={kvfeeder33Option}
              />
            </Form.Item>



             <Form.Item label="11 KV" name="kv11" rules={[
              {
                required: true,
                message: 'Please input 11 KV Feeder Name!',
              },
            ]}>
              <Select
               
                showSearch
                placeholder="11 KV Feeder Name"
                optionFilterProp="children"
                onChange={onChange11kva}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={kvfeeder11Option}
              />
            </Form.Item>
           {1 == 2 &&  <Form.Item label="DT" name="dt" rules={dtrequired}>
              <Select
               
                showSearch
                placeholder="DT"
                optionFilterProp="children"
                onChange={onChange11kva}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={kvfeeder11Option}
              />
            </Form.Item>}

            <Form.Item label="Upload" name="file" rules={[
              {
                required: true,
                message: 'Please Upload File!',
              },
            ]}>



                <Upload multiple={false} beforeUpload={beforeUpload} maxCount={1} name="geofile" action={Config?.url+'uploadfile'} onChange={onuploadChange}>
    <Button icon={<UploadOutlined />}>Click to Upload</Button>
  </Upload>

            </Form.Item>
                     
      
            


           



            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8"> <Button type="primary" htmlType="submit" className="login-form-button"> Save </Button></div>

            </div>


          </Form>

        </div>
      </div>
    </>)
}

export default UploadData;