import { Button, Form, Input, Switch, notification, Select } from 'antd';
import { useState, useEffect } from 'react';
import { getUserDetail } from '../../services/user.server';
import CustomerObj from '../../utility/customerclass';
import commonObj from '../../utility/commonclass';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
const Edit = () => {
  const [form] = Form.useForm();
  const componentSize = 'large';
  const [userData, setuserData] = useState({});
  const [areaType, setareaType] = useState("")
  const [section, setsection] = useState({ list: [], options: [] })
  const [kv33, setkv33] = useState([])
  const [kv11, setkv11] = useState([])
  const { id } = useParams();
  useEffect(() => {
    getUserDetail(id).then((data) => {
      console.log(data);
        let assign=(data?.assignArea== null ||  typeof(data?.assignArea)=='undefined')?{}:JSON.parse(data.assignArea);
        console.log('Assign');
        console.log(assign);
        if(Object.keys(assign).length > 0){
          if(assign.areatype=="11KV"){
          //  let kvval11=assign.kv11.split(",");
            assign.kv11=assign.kv11.split(",");
            assign.kv11 = assign.kv11.map(function (x) { 
              return parseInt(x); 
            });
         //  console.log(kvval11);
            getkvfeeder33({id:assign?.section});
            getkvfeeder11({id:assign?.kv33});
          }else if(assign.areatype=="Section"){

            assign.section=assign.section.split(",");
            assign.section = assign.section.map(function (x) { 
              return parseInt(x); 
            });

          }else if(assign.areatype=="33KV"){

            assign.kv33=assign.kv33.split(",");
            assign.kv33 = assign.kv33.map(function (x) { 
              return parseInt(x); 
            });

            getkvfeeder33({id:assign?.section});
            
          }
          setareaType(assign.areatype);
        }
      setuserData({ ...data,...assign });
    }).catch((err) => {
      console.log(err)
    })

    async function getSection() {
      const sectionlist = await CustomerObj.getSection({});



      const worktypefiletrlist = sectionlist.map((item) => {
        return { value: item.id, label: `${item.section_name} (${item.section_code})`, item: item }

      })
      setsection({ list: sectionlist, options: worktypefiletrlist });

    }
    getSection();

  }, [])
  const onFinish = async (values) => {
    console.log('Success:', values);
    console.log(userData);
      let assignArea={}

    try {
      if(values["areatype"]=='11KV'){
        assignArea={areatype:values["areatype"],section:values?.section,kv33:values?.kv33,kv11:values?.kv11.join(",")}
      }else if(values["areatype"]=='Section'){
        assignArea={areatype:values["areatype"],section:values?.section.join(",")}

      }else if(values["areatype"]=='33KV'){
        assignArea={areatype:values["areatype"],section:values?.section,kv33:values?.kv33.join(",")}

      }

      
     let consumerArea="";
     if(commonObj.isEmpty(values.consumerArea)==false){
      consumerArea= values.consumerArea.join(",");
     }
    
      
      const user = { ...values, id: userData.id,assignArea:JSON.stringify(assignArea),consumerArea:consumerArea }
      //console.log(user);
     // return false;
      const customerinfo = await CustomerObj.UpdateCustomer(user);
      //console.log(customerinfo);
      if (Object.keys(customerinfo).length > 0) {
        // form.resetFields();
      }
    } catch (err) {
      notification.error({ message: err.message })

    }



  };

  async function getkvfeeder33(arg) {

    const kvfeeder33list = await CustomerObj.getkvfeeder33({section_id:arg?.id});

    const kvfeeder33listfiletr = kvfeeder33list.map((item, key) => {
      return { value: item.id, label: `${item.feeder_name}`, item: item }

    })
    setkv33(kvfeeder33listfiletr)

  }

  async function getkvfeeder11(arg) {
    console.log(arg)
    const kvfeeder11list = await CustomerObj.getkvfeeder11({kvfeeder33_id:arg?.item?.id});
    const kvfeeder11listfiletr = kvfeeder11list.map((item,key) => {
      return { value: item.id, label: `${item.feeder_name}`,item:item }
  
    })
    
    setkv11(kvfeeder11listfiletr);
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const handelChange = (e) => {
    console.log(e.target.value);
    userData[e.target.name] = e.target.value;
    setuserData(userData);
    // console.log(e.target.name);



  }

  return (
    <>
      <div className="card mb-4">
        <div className="card-header">User Edit</div>
        <div className="card-body">
          {userData.email && <Form
            form={form}
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 14,
            }}
            layout="horizontal"


            size={componentSize}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={userData}
          >

            <Form.Item label="First Name" name="first_name">
              <Input onChange={(e) => handelChange(e)} />
            </Form.Item>
            <Form.Item label="Last Name" name="last_name">
              <Input onChange={(e) => handelChange(e)} />
            </Form.Item>
            <Form.Item label="Email" name="email"  >
              <Input onChange={(e) => handelChange(e)} disabled />
            </Form.Item>
            <Form.Item label="Mobile" name="mobile">
              <Input onChange={(e) => handelChange(e)} />
            </Form.Item>
            <Form.Item label="Status" name="status">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={(userData.status) ? true : false}
                onChange={(e) => handelChange(e)}

              />
            </Form.Item>
            <div className="assignBox">
              <h5>Assign</h5>

              <Form.Item label="Area Type" name="areatype" rules={[
                {
                  required: true,
                  message: 'Please Select Area Type!',
                },
              ]}>
                <Select

                  showSearch
                  placeholder="Select Section"
                  optionFilterProp="children"
                  onChange={(e, v) => {
                    console.log(e);
                    console.log(v);
                    setareaType(e);
                    //   getkvfeeder11(e);


                  }}

                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={[{ value: "Section", label: "Section" }, { value: "33KV", label: "33KV" }, { value: "11KV", label: "11KV" }]}
                />
              </Form.Item>

              {
                areaType == 'Section' && <Form.Item label="Section" name="section" rules={[
                  {
                    required: true,
                    message: 'Please Select Section!',
                  },
                ]}>
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Select Section"
                    optionFilterProp="children"
                    onChange={(e, v) => {
                      console.log(e);
                      console.log(v);
                      //   getkvfeeder11(e);


                    }}

                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={section.options}
                  />
                </Form.Item>
              }

              {areaType == '33KV' && <>

                <Form.Item label="Section" name="section" rules={[
                  {
                    required: true,
                    message: 'Please Select Section!',
                  },
                ]}>
                  <Select

                    showSearch
                    placeholder="Select Section"
                    optionFilterProp="children"
                    onChange={(e, v) => {
                      console.log(e);
                      console.log(v);
                      getkvfeeder33(v?.item);


                    }}

                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={section.options}
                  />
                </Form.Item>
                {
                  kv33.length > 0 && <><Form.Item label="33 KV" name="kv33" rules={[
                    {
                      required: true,
                      message: 'Please Select 33 KV!',
                    },
                  ]}>
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select 33 KV"
                      optionFilterProp="children"
                      onChange={(e, v) => {
                        console.log(e);
                        console.log(v);
                        //   getkvfeeder33(e);


                      }}

                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={kv33}
                    />
                  </Form.Item></>

                }


              </>}

              {areaType == '11KV' && <>

<Form.Item label="Section" name="section" rules={[
  {
    required: true,
    message: 'Please Select Section!',
  },
]}>
  <Select

    showSearch
    placeholder="Select Section"
    optionFilterProp="children"
    onChange={(e, v) => {
      console.log(e);
      console.log(v);
      getkvfeeder33(v?.item);


    }}

    onSearch={onSearch}
    filterOption={(input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }
    options={section.options}
  />
</Form.Item>
{
  kv33.length > 0 && <><Form.Item label="33 KV" name="kv33" rules={[
    {
      required: true,
      message: 'Please Select 33 KV!',
    },
  ]}>
    <Select
     
      showSearch
      placeholder="Select 33 KV"
      optionFilterProp="children"
      onChange={(e, v) => {
        console.log(e);
        console.log(v);
        getkvfeeder11(v);


      }}

      onSearch={onSearch}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={kv33}
    />
  </Form.Item></>

}

{
  kv11.length > 0 && <><Form.Item label="11 KV" name="kv11" rules={[
    {
      required: true,
      message: 'Please Select 11 KV!',
    },
  ]}>
    <Select
      mode="multiple"
      showSearch
      placeholder="Select 11 KV"
      optionFilterProp="children"
      onChange={(e, v) => {
        console.log(e);
        console.log(v);
       // getkvfeeder11(v);


      }}

      onSearch={onSearch}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={kv11}
    />
  </Form.Item></>

}




</>} </div>
<div className="assignBox">
              <h5>Consumer Assign</h5>
              </div>
              <Form.Item label="Section" name="consumerArea" rules={[
                  {
                    required: true,
                    message: 'Please Select Consumer Section!',
                  },
                ]}>
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Select Consumer Section"
                    optionFilterProp="children"
                    onChange={(e, v) => {
                     


                    }}

                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={section.options}
                  />
                </Form.Item>

            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8"> <Button type="primary" htmlType="submit" className="login-form-button"> Save </Button></div>

            </div>


          </Form>
          }
        </div>
      </div>
    </>
  )
}

export default Edit;