import {React} from 'react';
import {Link} from 'react-router-dom'
import { useState } from 'react';
import AuthObj from '../../utility/authclass';
import { useEffect } from 'react';
import commonObj from '../../utility/commonclass';
export default function MenuComponent(props){
    
    const [currentuser,setcurrentUser]=useState({});
    const elementclass=props?.className!='undefined'?props?.className:"";
    useEffect(()=>{
      let user=  AuthObj.getUserSession();
      console.log(user);
        if(commonObj.isEmpty(user)==false){
            setcurrentUser(user.user);
        }

    },[])
    let Menu=[
            {
                label:'Dashboard',
                link:'',
                submenu:[]
            },


            /*{
                label:'Page Management',
                link:'',
                submenu:[]
            },
            {
                label:'Product Management',
                link:'',
                submenu:[]
                
            },*/
            {
                label:'User Management',
                link:'',
                
                submenu:[
                    {
                        label:'User List',
                        link:'/admin/user/list',

                    },
                    {
                        label:'User Add',
                        link:'/admin/user/add',

                    }
                ]
            },

            {
                label:'Setting',
                link:'',
                
                submenu:[
                    {
                        label:'Load Work Order',
                        link:'/admin/workorder/loadlist',

                    },
                    {
                        label:'Work Order List',
                        link:'/admin/workorder/list',

                    },
                    {
                        label:'Collection Layer',
                        link:'/admin/collection/list',

                    }
                    ,
                    {
                        label:'Division List',
                        link:'/admin/division/list',

                    } ,
                    {
                        label:'Section List',
                        link:'/admin/section/list',

                    },
                    {
                        label:'KVfeeder33 List',
                        link:'/admin/kvfeeder33/list',

                    },
                    {
                        label:'KVfeeder11 List',
                        link:'/admin/kvfeeder11/list',

                    },
                    {
                        label:'Transformer List',
                        link:'/admin/kvfeeder11/list',

                    },
                    {
                        label:'ReAssign Migration ID',
                        link:'/admin/reassign',

                    },
                    {
                        label:'Upload Geojson',
                        link:'/admin/uploadgeojson',

                    }
                ]
            },
           
            {
                label:'Report',
                link:'',
                submenu:[{
                    label:'Collection List',
                    link:'/admin/report/list',

                },

                {label:'Map View List',
                link:'/admin/map/report',

            },
                {
                    label:'Task List',
                    link:'/admin/task/list',

                },{
                    label:'User Wise Report',
                    link:'/admin/report/userwise',

                }
            ]
            },
            


    ]
    
  let menutoggle= (event,key)=>{     
    event.preventDefault();    
    if(event.target.parentElement.classList.contains('show')){        
        event.target.parentElement.classList.remove('show');
    }else{
        event.target.parentElement.classList.add('show');
    }
   
    /*Menu[key].showmenu=true;
    console.log(Menu[key]);
    console.log('menu click');*/

  }
return (
    
    <>
      <div className={`sidebar sidebar-dark sidebar-fixed ${elementclass}`} id="sidebar">
      <div className="sidebar-brand d-none d-md-flex">
      	<img src={process.env.PUBLIC_URL+'/images/logo.jpeg'}  id="logo-image" alt="logo" />
        
      </div>
      <ul className="sidebar-nav" data-coreui="navigation" data-simplebar="">
            {currentuser.role !=3 && 
             Menu.map( (item,itemkey) => {
                return (
                    
                        <li key={item.label} className={(item?.submenu?.length > 0 && item.showmenu===true)?'nav-group show':(item?.submenu?.length > 0)?'nav-group':'nav-item'}>
                            
                        {
                        (item?.submenu?.length > 0) && 
                        <>
                            <a className="nav-link nav-group-toggle" href="false" onClick={(e)=>menutoggle(e,itemkey)}>
                            <span className="nav-icon"></span>{item.label}</a>
                            <ul className="nav-group-items">
                                {
                                    item.submenu.map((subitem,subitemkey)=>{
                                        return  <li className="nav-item" key={subitemkey}><Link className={'nav-link'} to={subitem.link}> <span className="nav-icon"></span>{subitem.label}</Link></li>

                                    })
                                }
                                   
                            </ul>
                        </>

                        }

                        {(item?.submenu?.length === 0) &&                         
                            <Link className={'nav-link'} to="/admin"> <span className="nav-icon"></span>{item.label}</Link>
                       

                        }
                        
                        </li> 
                    
                

                )

                })   
            }

{currentuser.role ==3 &&  <><li key={"Collection"} className='nav-item'>
    
<Link className={'nav-link'} to="/admin/report/list"> <span className="nav-icon"></span>Collection List</Link>
</li><li key={"MapCollection"} className='nav-item'>
    
    <Link className={'nav-link'} to="/admin/map/report"> <span className="nav-icon"></span>Map Collection List</Link>
    </li></>} 
      </ul>
      
    </div>

    </>
)

}