import moment from 'moment';
class Common {
    constructor() {


    }

    isEmptyObject(obj) {

        if (obj === null) {
            return true;
        } else {

            return JSON.stringify(obj) === '{}'
        }

    }
    isEmpty(x) {

        return (                                                           //don't put newline after return
        (typeof x == 'undefined')
              ||
        (x == null)
              ||
        (x === false)        //same as: !x
              ||
        (x.length == 0)
              ||
    //    (x == 0)            // note this line, you might not need this. 
      //        ||
        (x == "")
              ||
        (!/[^\s]/.test(x))
              ||
        (/^\s*$/.test(x))
    );
    }

    dateFormat(date){
        
        return moment(date).format('DD/MM/YYYY');
    }
    dateDBFormat(date){
        
        return moment(date).format('YYYY-MM-DD');
    }
    dateFormaturl(date){
        
        return moment(date).format('DD-MM-YYYY');
    }

    datetimeFormat(date){
        
        return moment(date).format('DD MMMM YYYY hh:mm:ss a');
    }

}
export default new Common;